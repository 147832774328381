import React from 'react';

import useAdditionalContactU16AddEditContact from './UseAdditionalContactU16AddEditContact';
import AdditionalContactAddEditForm from '../AdditionalContactAddEditForm';
import Views from '../../../Views';
import NavLinks from '../../../NavLinks';
import ViewTemplate from '../../../components/viewTemplate/ViewTemplate';
import AddressBook from '../../../components/AddressBook/AddressBook';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const AdditionalContactU16AddEditContact = () => {
  const {
    formState,
    errorState,
    onFormValueChanged,
    onValueTextPairChanged,
    setFormData,
    onNextButtonClicked,
    validateState,
    onBackButtonClicked
  } = useAdditionalContactU16AddEditContact();

  return (
    <ViewTemplate
      viewName={Views.ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT}
      onBackButtonClicked={onBackButtonClicked}
      onNextButtonClicked={onNextButtonClicked}>
      {validateState.isValidating === true && (
        <PopUpModal
          displayPopUp={true}
          maxWidth={250}
          title="Validating..."
          widthPct={50} />
      )}
      <AddressBook navlink={NavLinks.ADDITIONAL_CONTACTS_U16_ADD_EDIT_CONTACT} />
      <AdditionalContactAddEditForm
        formState={formState}
        errorState={errorState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged} 
        setFormData={setFormData} />
    </ViewTemplate>
  );
};

export default AdditionalContactU16AddEditContact;